import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Platform } from '@ionic/angular';
import { FirebaseApp, getApp } from 'firebase/app';
import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app';
import { Analytics,getAnalytics, logEvent, setUserId,setUserProperties, } from 'firebase/analytics';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

import { TranslateConfigService } from 'src/app/translate-config.service';
import { GeoService } from "../services/geo.service";

import {
  Auth,
  getAuth,
  onAuthStateChanged ,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  AuthInstances
} from "@angular/fire/auth";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private app = getApp();
  private analytics = getAnalytics(this.app);
 lang = 'en';
 private isWeb: boolean;

constructor(private titleService: Title,private translate: TranslateService,private platform: Platform,private translateConfigService: TranslateConfigService,private GeoService:GeoService) {
    const initialLanguage = this.translateConfigService.getCurrentLang();
    this.lang = initialLanguage;
    this.setUserProperty({name: 'language', value: initialLanguage});
    //this.logUserPlatform ();
    this.isWeb = !Capacitor.isNativePlatform();
    console.log(`isWeb: ${this.isWeb ? 'true' : 'false'}`);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        return new Promise<void>(async (resolve) => {
            console.log('Language changed to: ', event.lang);
            this.lang = event.lang;
            await this.setUserProperty({name: 'language', value: event.lang});
            resolve();
        });
    });
}

async setPageTitle(title: string) {
    title = 'BERMAD Connect - ' + title;
    await this.logScreenView(title);
    this.titleService.setTitle(title);
}

async setUserId(uid: any) {
    console.log(`setUserId: ${uid}`);
    try {
        //console.log(`Before setUserId: ${screenName}`);
        if (Capacitor.getPlatform() == 'web') {
            // We are on the web, use Firebase JavaScript SDK
            const analytics = getAnalytics();
            setUserId(analytics, uid);
        } else {
            // We are on mobile, use @capacitor-community/firebase-analytics
            await FirebaseAnalytics.setUserId({ userId: uid });
        }
    } catch (error) {
        console.error('Error in setUserId:', error);
    }
  }


  async setUserProperty (params: any) {
    console.log(`setUserProperty : ${params}`);
    try {
        //console.log(`Before setScreenName: ${screenName}`);
        if (Capacitor.getPlatform() == 'web') {
            // We are on the web, use Firebase JavaScript SDK
            setUserProperties(this.analytics,params); // Add an empty object as the second argument
        } else {
            // We are on mobile, use @capacitor-community/firebase-analytics
            await FirebaseAnalytics.setUserProperty(params);
        }
    } catch (error) {
        console.error('Error in setUserProperty:', error);
    }
  }


  async logUserPlatform () {
    console.log(`logUserPlatform : ${Capacitor.getPlatform()}`);
    try {
        //console.log(`Before setScreenName: ${screenName}`);
        if (Capacitor.getPlatform() == 'web') {
            // We are on the web, use Firebase JavaScript SDK
            logEvent(this.analytics, 'user_platform', { platform: 'desktop' });
        } else {
            // We are on mobile, use @capacitor-community/firebase-analytics
            await FirebaseAnalytics.logEvent({ name: 'user_platform', params: { platform: Capacitor.getPlatform() } });
        }
    } catch (error) {
        console.error('Error in logUserPlatform:', error);
    }
  }

  async logScreenView(screenName: string) {
    console.log(`logScreenView: ${screenName}`);
    var platform = this.isWeb ? 'web' : Capacitor.getPlatform();

    this.lang = this.translateConfigService.getCurrentLang();
    var params: { [key: string]: any } = {};

    params['platform'] = platform;
    params['language'] = this.lang;
    params['user_id'] = await this.get_user_id();
    console.log(`screenName event: ${screenName}`, params);

    try {
        //console.log(`Before setScreenName: ${screenName}`);
        if (platform == 'web') {
            // We are on the web, use Firebase JavaScript SDK
            logEvent(this.analytics, 'screen_view', { firebase_screen: screenName, firebase_screen_class: screenName+'Page',params });
            //this.logEvent('screen_view', { screen_name: screenName });
        } else {
            // We are on mobile, use @capacitor-community/firebase-analytics
            await FirebaseAnalytics.setScreenName({ screenName: screenName, nameOverride: screenName+'Page' });
        }

        //console.log(`After setScreenName: ${screenName}`);
    } catch (error) {
        console.error('Error in logScreenView:', error);
    }
    //console.log(`logScreenView end: ${screenName}`);
  }


  async get_user_id() {
    var user_id = localStorage.getItem('fuid');
    if (!user_id){
      // get user id from firebase auth
      const  theAuth = getAuth()
      onAuthStateChanged(theAuth, (user) => {
        console.log(user);
        if (user) {
          console.log('User is  logged in');
          user_id = user.uid;
          localStorage.setItem('fuid', user_id);
        } else {
          console.log('User is not logged in');
          user_id = 'guest';
        }
      });
    }
    if (!user_id){
      user_id = 'guest';
    }
    return user_id;
  }




  async logEvent(eventName: string, params: { [key: string]: any }) {
    // get current langhage
    this.lang = this.translateConfigService.getCurrentLang();
    params['platform'] = this.isWeb ? 'web' : Capacitor.getPlatform();
    params['language'] = this.lang;
    params['user_id'] = await this.get_user_id();
    params['country'] = await this.GeoService.get_country();
    console.log(`Logging event: ${eventName}`, params);

    if (this.analytics) {
      // We are on the web, use Firebase JavaScript SDK
      logEvent(this.analytics, eventName, params);
    } else {
      // We are on mobile, use @capacitor-community/firebase-analytics
      await FirebaseAnalytics.logEvent({ name: eventName, params });
    }
    console.log(`logEvent: ${eventName}`);
  }


}
