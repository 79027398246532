import { NgModule,inject,ApplicationRef } from '@angular/core';

import { CUSTOM_ELEMENTS_SCHEMA, isDevMode } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { IonicStorageModule } from '@ionic/storage-angular';

import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import { File } from '@ionic-native/file/ngx';


import { SharedModule } from './components/shared/shared.module';


import { FirebaseApp } from '@angular/fire/app';
import { provideFirebaseApp, initializeApp,getApp } from '@angular/fire/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider,provideAppCheck,AppCheck  } from '@angular/fire/app-check';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideMessaging,getMessaging,getToken,onMessage} from '@angular/fire/messaging';
import { provideAuth, getAuth, onAuthStateChanged } from '@angular/fire/auth';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { ServiceWorkerModule } from '@angular/service-worker';


import { LightboxModule } from 'ngx-lightbox';

import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

import { SplashScreenComponent } from  '../app/components/splash-screen/splash-screen.component';

import {
  connectAuthEmulator,

  indexedDBLocalPersistence,
  initializeAuth,
} from '@angular/fire/auth';
import { Capacitor } from '@capacitor/core';
import { environment } from '../environments/environment';
import { AppLongPressDirective } from './app-long-press.directive';


import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: environment.firebaseConfig.apiKey,
  authDomain: environment.firebaseConfig.authDomain,
  projectId: environment.firebaseConfig.projectId,
  storageBucket: environment.firebaseConfig.storageBucket,
  messagingSenderId: environment.firebaseConfig.messagingSenderId,
  appId: environment.firebaseConfig.appId,
};




@NgModule({
  declarations: [AppComponent, SplashScreenComponent, AppLongPressDirective,],
  imports: [BrowserModule,LeafletModule, IonicModule.forRoot(), AppRoutingModule,HttpClientModule,HttpClientModule,LightboxModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: (createTranslateLoader),
      deps: [HttpClient]
    }
  }),
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    //provideAuth(() => getAuth()),
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
        });
      } else {
        const auth = getAuth();
          //connectAuthEmulator(auth, 'http://localhost:8100');
        return auth;
      }
    }),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideMessaging(() => getMessaging()),



    //provideAppCheck( () => initializeAppCheck(getApp(),{provider:new ReCaptchaEnterpriseProvider('6Lcm7PwoAAAAACjiXAwr1erXiU7TszUn10Euq6Vc'),isTokenAutoRefreshEnabled:true})),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      //enabled: !isDevMode(),
      enabled: environment.serviceWorker,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },File],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
 /*
  private appCheck: AppCheck = inject(AppCheck);
  constructor (private appcheck:AppCheck){

  }
*/

constructor (private appRef: ApplicationRef,){


}





ionViewDidEnter() {

}
  async ngOnInt(){



   // console.log(this.appcheck)

  }






}



export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}



